import React from 'react'
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
// import * as Icon from 'react-bootstrap-icons';
import { useLocation, useNavigate } from "react-router-dom";
import {  } from "react-router-dom";
// import logo from '../../assets/images/logoHori.webp'
import Offcanvas from 'react-bootstrap/Offcanvas';
import PatientForm1 from '../../assets/pdf/New_Patient_Form_PMC.pdf'
import PatientForm2 from '../../assets/pdf/New_Patient_Form_PMC_Pediatrics.pdf'
// import bag from '../../assets/images/bag.webp'
// import {TelephoneFill, EnvelopeFill} from 'react-bootstrap-icons'; {/*, Facebook, Twitter, Instagram, Linkedin */}
// import user from '../../assets/images/user.webp'


const Header = () => {
  const location = useLocation();
  
  const { pathname } = location;
//   const [locationParam, setLocationParam] = useState([]);
  
//   useEffect(() => {
//     setLocationParam([]);
//     const locations = pathname.split('/');
//     //loop through the locations and set the locationParam
//     locations.forEach((location) => {
//       if (location !== "") {
//         setLocationParam((locationParam) => [...locationParam, location]);
//       }
//     })
//   }, [pathname]);
  
  const splitLocation = pathname.split("/");
  let navigate = useNavigate();


  const handleParentClick = (event, path) => {
    // event.preventDefault();
    navigate(path, { replace: true });

};

  return (
    <>
      <Navbar expand="xl" className="bg-white py-1" id="website-nav" style={{borderBottom:"5px solid #f51a44"}}>
        <Container fluid className='px-md-5'>
            <Navbar.Brand><Link
            className="flex-wrap navbar-brand d-flex align-items-baseline"
            to="/"
            >
            {/* <img
                src={logo}
                alt="Logo"
                className="py-2 img-fluid logo-img"
            /> */}
            <span className="fs-zk-22 header-logo text-raleway" style={{fontWeight:"600"}}>Premier Medical Clinic</span>
            </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} />
            <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-xl`}
            aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
            placement="start"
            className=""
            >
            <Offcanvas.Header closeButton className='pb-0'>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
                    <Link
                    className="flex-wrap navbar-brand d-flex align-items-baseline"
                    to="/"
                    >
                    {/* <img
                        src={logo}
                        alt="Logo"
                        className="py-2 img-fluid"
                        style={{height: "35px"}}
                    /> */}
                    <span className="text-zk-primary fs-zk-20">Premier Medical Clinic</span>

                    </Link>
                </Offcanvas.Title>
            </Offcanvas.Header>
            {/* <hr /> */}
            <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3 text-raleway website-np align-items-nav">
                    <Link to="/" className={splitLocation[1] === "" ? "active py-2 fs-zk-16 nav-link text-raleway" : "py-2 fs-zk-16 nav-link text-raleway"} style={{fontWeight:"500"}}>Home</Link>
                    <Link to="/about-us" className={splitLocation[1] === "about-us" ? "active py-2 fs-zk-16 nav-link text-raleway" : "py-2 fs-zk-16 nav-link text-raleway"} style={{fontWeight:"500"}}>About Us</Link>
                    
                    <Link to="/providers" className={splitLocation[1] === "providers" ? "active py-2 fs-zk-16 nav-link text-raleway" : "py-2 fs-zk-16 nav-link text-raleway"} style={{fontWeight:"500"}}>Providers</Link>
                    <Link to="/blogs" className={splitLocation[1] === "blogs" ? "active py-2 fs-zk-16 nav-link text-raleway" : "py-2 fs-zk-16 nav-link text-raleway"} style={{fontWeight:"500"}}>Blogs</Link>
                    
                    {/* <Link to="/how-it-works" className={splitLocation[1] === "" ? "active py-2 fs-zk-16 nav-link text-raleway" : "py-2 fs-zk-16 nav-link text-raleway"} style={{fontWeight:"500"}}>Services</Link> */}
                    <NavDropdown renderMenuOnMount={true} onToggle={e=>(handleParentClick(e,"/services"))}
                        className={splitLocation[1] === "services" || splitLocation[1] === "primary-care" || splitLocation[1] === "urgent-care" || splitLocation[1] === "skin-cancer" || splitLocation[1] === "cardiology" || splitLocation[1] === "vaccinations" || splitLocation[1] === "pediatric-care" || splitLocation[1] === "internal-medicine" || splitLocation[1] === "behavioral-health" ? "active py-md-2 fs-zk-16 nav-link text-raleway sub-nav-menu" : "py-md-2 fs-zk-16 nav-link text-raleway sub-nav-menu"} style={{fontWeight:"500"}}
                        title="Services"
                        id={`offcanvasNavbarDropdown-expand-lg1`}
                    >
                        {/* <Link to="/about-us" className={splitLocation[1] === "about-us" ? "active py-2 fs-np-16 nav-link text-work-sans" : "py-2 fs-np-16 nav-link text-work-sans"} style={{fontWeight:"500"}}>About Us</Link> */}
                        <Link to="/primary-care" className={splitLocation[1] === "primary-care" ? "active py-2 fs-zk-16 nav-link text-raleway" : "py-2 fs-zk-16 nav-link text-raleway"} style={{fontWeight:"500"}}>Primary Care</Link>
                        <Link to="/urgent-care" className={splitLocation[1] === "urgent-care" ? "active py-2 fs-zk-16 nav-link text-raleway" : "py-2 fs-zk-16 nav-link text-raleway"} style={{fontWeight:"500"}}>Urgent Care</Link>
                        <Link to="/skin-cancer" className={splitLocation[1] === "skin-cancer" ? "active py-2 fs-zk-16 nav-link text-raleway" : "py-2 fs-zk-16 nav-link text-raleway"} style={{fontWeight:"500"}}>Skin Cancer</Link>
                        <Link to="/cardiology" className={splitLocation[1] === "cardiology" ? "active py-2 fs-zk-16 nav-link text-raleway" : "py-2 fs-zk-16 nav-link text-raleway"} style={{fontWeight:"500"}}>Cardiology</Link>
                        <Link to="/vaccinations" className={splitLocation[1] === "vaccinations" ? "active py-2 fs-zk-16 nav-link text-raleway" : "py-2 fs-zk-16 nav-link text-raleway"} style={{fontWeight:"500"}}>Vaccinations</Link>
                        <Link to="/pediatric-care" className={splitLocation[1] === "pediatric-care" ? "active py-2 fs-zk-16 nav-link text-raleway" : "py-2 fs-zk-16 nav-link text-raleway"} style={{fontWeight:"500"}}>Pediatric Care</Link>
                        <Link to="/internal-medicine" className={splitLocation[1] === "internal-medicine" ? "active py-2 fs-zk-16 nav-link text-raleway" : "py-2 fs-zk-16 nav-link text-raleway"} style={{fontWeight:"500"}}>Internal Medicine</Link>
                        <Link to="/behavioral-health" className={splitLocation[1] === "behavioral-health" ? "active py-2 fs-zk-16 nav-link text-raleway" : "py-2 fs-zk-16 nav-link text-raleway"} style={{fontWeight:"500"}}>Behavioral Health</Link>
                        
                    </NavDropdown>
                    <NavDropdown renderMenuOnMount={true}
                        className={splitLocation[1] === "/patient-form" ? "active py-md-2 fs-zk-16 nav-link text-raleway sub-nav-menu" : "py-md-2 fs-zk-16 nav-link text-raleway sub-nav-menu"} style={{fontWeight:"500"}}
                        title="Patient Forms"
                        id={`offcanvasNavbarDropdown-expand-lg2`}
                    >
                        {/* <Link to="/about-us" className={splitLocation[1] === "about-us" ? "active py-2 fs-np-16 nav-link text-work-sans" : "py-2 fs-np-16 nav-link text-work-sans"} style={{fontWeight:"500"}}>About Us</Link> */}
                        <a href={PatientForm1} target="_blank" rel="noreferrer" className="py-2 fs-zk-16 nav-link text-raleway" style={{fontWeight:"500"}}>New Patient Form</a>
                        <a href={PatientForm2} target="_blank" rel="noreferrer" className="py-2 fs-zk-16 nav-link text-raleway" style={{fontWeight:"500"}}>New Patient Form - Pediatrics</a>
                        
                    </NavDropdown>
                    {/* <Link to="/" className={splitLocation[1] === "" ? "active py-2 fs-zk-16 nav-link text-raleway" : "py-2 fs-zk-16 nav-link text-raleway"} style={{fontWeight:"500"}}>Patient Forms</Link> */}
                    <Link to="/contact-us" className={splitLocation[1] === "contact-us" ? "active py-2 fs-zk-16 nav-link text-raleway" : "py-2 fs-zk-16 nav-link text-raleway"} style={{fontWeight:"500"}}>Contact Us</Link>
                    <a href="tel:+12562591234" className="py-2 fs-zk-16 nav-link text-poppins d-none d-xl-block bg-zk-secondary text-white px-2" style={{fontWeight:"300"}}>
                        {/* <Icon.BagCheck className="text-zk-secondary2" style={{fontSize:"20px"}}/> */}
                        {/* <img src={bag} alt="cart" style={{height:"23px"}}/> */}
                        256-259-1234
                    </a>
                    {/* <Link to="/" className={splitLocation[1] === "" ? "active py-2 fs-zk-16 nav-link text-raleway d-none d-xl-block" : "py-2 fs-zk-16 nav-link text-raleway d-none d-xl-block"} style={{fontWeight:"500"}}>
                        <img src={user} alt="user" style={{height:"23px"}}/>
                    </Link> */}

                </Nav>
                
            </Offcanvas.Body>
            </Navbar.Offcanvas>
            
        </Container>
        </Navbar>
    </>
  )
}

export default Header