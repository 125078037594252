import React from 'react'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import Icon from '../assets/images/Thankyou/Icon.png'
import { Helmet } from 'react-helmet'

const ThankYou = () => {
    return (
        <>
        <Helmet>
                <title> Thank You - Premier Medical Clinic</title>
        </Helmet>
        <Header/>
        <section id='website-responsive' className="container-fluid py-5">
            <div className="row justify-content-center">
                <div className="col-11 col-lg-7 bg-light text-center text-black p-5 shadow-zk-1">
                    <div className="pb-4">
                        <img className='' src={Icon} alt="Envelope" style={{width:"120px"}} />
                    </div>
                    <div className='px-lg-5'>
                    <div className="fs-zk-35 fw-700">
                    Thank You!
                    </div>
                    <div className="fs-zk-16 fw-400">
                    Thank you for your request for an appointment with <span className='fw-600'> Premier Medical Clinic. </span> We will contact you shortly to confirm your scheduled time for your appointment. Please call our office at <a href="tel:2567279816" className='text-black text-decoration-none fw-600'> (256) 727-9816 </a>  if you have any questions.
                    </div>
                    </div>
                </div>
            </div>
        </section>
        <Footer/>
        </>
    )
}

export default ThankYou