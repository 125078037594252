import React from 'react'
import { Spinner } from 'react-bootstrap'

const Loading = () => {
  return (
  <div id="splash-screen" className="splash-screen">
      <span>
        {/* spinner bootstrap */}
        <Spinner animation="border" variant="info" />
      </span>
  </div>
  )
}

export default Loading